<template>
  <div id="CONTENT">
    <tagline :sitename="sitename" :title="title"></tagline>
    <navidiv></navidiv>
    <!-- H1 -->
    <div class="h1-binder">
      <h1 class="b7">{{ title }}</h1>
      <!-- 検索フォーム -->
      <form
        method="get"
        action="/annotations/search"
        id="SEARCH-FORM"
        name="Search"
      >
        <input type="text" value="" name="keywords" class="keyword" /><input
          type="image"
          src="/img/btn_search.gif"
          value=""
          alt="検索"
          name=""
          class="submit"
        />
      </form>
      <!-- /検索フォーム -->
    </div>
    <!-- /H1 -->
    <!-- パンくず -->
    <div id="TOPIC-PATH">
      <a href="http://ebiki.jp/">トップページ</a> &gt;
      <a href="/">絵引データベース</a> &gt;
      <a :href="'/series/view/' + this.series.id">{{ series.title }}</a> &gt;
      <a :href="'/works/view/' + this.work.id">{{ work.title }}</a>
    </div>
    <!-- /パンくず -->
    <!-- コンテンツ -->
    <div id="CONTENT-BODY">
      <!-- H2 -->
      <div class="h2-binder big-margin-bottom">
        <h2>
          {{ work.title }}<span>（{{ work.title_kana }}）</span>
        </h2>
        <p class="date">
          シリーズ：<a :href="'/series/view/' + this.series.id">{{
            series.title
          }}</a>
        </p>
      </div>
      <!-- /H2 -->
      <!-- 2段組み -->
      <div class="pane-binder">
        <!-- 左側 -->
        <div class="left">
          <!-- 解説・現代語訳 -->
          <template
            v-if="
              this.work.description ||
              this.work.interpretation ||
              this.work.translation
            "
          >
            <h3>解説／画中のテキスト</h3>
            <dl class="commentary">
              <template v-if="this.work.description">
                <dt>作品解説</dt>
                <dd v-html="this.work.description"></dd>
              </template>
              <template v-if="this.work.interpretation">
                <dt>画中のテキスト（釈文）</dt>
                <dd v-html="this.work.interpretation"></dd>
              </template>
              <template v-if="this.work.translation">
                <dt>画中のテキスト（現代語訳）</dt>
                <dd v-html="this.work.translation"></dd>
              </template>
            </dl>
          </template>
          <!-- /解説・現代語訳 -->
          <!-- H3 -->
          <h3>図像・事物一覧</h3>
          <!-- /H3 -->
          <!-- 一覧 -->
          <p class="list_notes">
            ※画像アイコンにカーソルを合わせると、右の作品画像に位置が表示されます
          </p>
          <paginate
            name="annotations"
            :list="this.ebikis"
            :per="this.total"
            :tag="'table'"
            :class="'base-skin'"
          >
            <tr>
              <th scope="col" class="first image">画像</th>
              <th scope="col">
                <span @click="sortBy('name_kana')" :class="thClass('name_kana')"
                  >図像・事物</span
                >
              </th>
              <th scope="col">
                <span
                  @click="sortBy('category_name_kana')"
                  :class="thClass('category_name_kana')"
                  >カテゴリー</span
                >
              </th>
            </tr>
            <template v-for="ebiki in paginated('annotations')">
              <tr :key="ebiki.id">
                <td>
                  <template v-if="ebiki.image">
                    <img
                      :src="ebiki.image"
                      width="70"
                      height="70"
                      :alt="ebiki.name"
                      :title="ebiki.name"
                      @mouseover="focusInEbiki(ebiki.id, $event)"
                      @mouseout="focusOutEbiki"
                    />
                  </template>
                  <template v-else>
                    <img src="/img/spacer.gif" width="70" height="70" alt="" />
                  </template>
                </td>
                <td>
                  <router-link
                    :to="{ path: '/annotations/view/' + ebiki.id }"
                    >{{ ebiki.name }}</router-link
                  >
                </td>
                <td>
                  <router-link
                    :to="{ path: '/motifs/view/' + ebiki.category_id }"
                    >{{ ebiki.category_name }}</router-link
                  >
                </td>
              </tr>
            </template>
          </paginate>
          <!-- 一覧 -->
        </div>
        <!-- /左側 -->
        <!-- 右側 -->
        <div class="right">
          <!-- 作品情報 -->
          <h3>作品情報</h3>
          <dl class="annotation large">
            <dt>シリーズ</dt>
            <dd>
              <a :href="'/series/view/' + this.series.id">{{
                this.series.title
              }}</a>
            </dd>
            <dt>作者名</dt>
            <dd>{{ this.work.author }}</dd>
            <dt>作者名よみ</dt>
            <dd>{{ this.work.author_kana }}</dd>
            <dt>出版者（版元）</dt>
            <dd>{{ this.work.publisher }}</dd>
            <dt>制作年（和暦）</dt>
            <dd>{{ this.work.issued_wareki }}</dd>
            <dt>制作年（西暦）</dt>
            <dd>{{ this.work.issued }}</dd>
            <dt>法量</dt>
            <dd>{{ this.work.size }}</dd>
            <dt>落款</dt>
            <dd>{{ this.work.signature }}</dd>
            <dt>公開日</dt>
            <dd>{{ this.work.published | moment("YYYY/MM/DD") }}</dd>
            <dt>最終更新日</dt>
            <dd>{{ this.work.modified | moment("YYYY/MM/DD") }}</dd>
          </dl>
          <!-- /作品情報 -->
          <!-- スクロールに追随するエリア -->
          <div id="MOVABLE-AREA">
            <h3>作品画像</h3>
            <!-- 画像 -->
            <div id="PAINTING">
              <img :src="this.work.image" width="300" alt="" />
            </div>
            <!-- /画像 -->
            <p class="btn-zoom">
              <a :href="this.work.image" target="_blank"
                ><img src="/img/btn_zoom.gif" alt="拡大表示"
              /></a>
            </p>
          </div>
          <!-- /スクロールに追随するエリア -->
        </div>
        <!-- /右側 -->
      </div>
      <!-- /2段組み -->
    </div>
    <!-- /コンテンツ -->
    <footerdiv></footerdiv>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { Backend } from "@/ajax/Backend";
const iiifCanvas = Backend.get("canvas");
const iiifAnnotationList = Backend.get("list");
const iiifLayer = Backend.get("layer");

// @ is an alias to /src
import tagline from "@/components/tagline.vue";
import navidiv from "@/components/navi.vue";
import footerdiv from "@/components/footer.vue";

import $ from "jquery";
import ebikiMixin from "@/mixins/ebikiMixin.js";

export default {
  name: "work",
  mixins: [ebikiMixin],
  components: {
    tagline,
    navidiv,
    footerdiv,
  },
  props: {
    work_id: { type: Number, required: true },
    sitename: { type: String, required: true },
    orgname: { type: String, required: true },
    manifest: { type: Object, required: true },
    manifest_id: { type: Number, required: true },
    title: { type: String, required: true },
    meta_description: { type: String, required: true },
    meta_keywords: { type: String, required: true },
  },
  data: function () {
    return {
      canvas: {},
      annotationlist: {},
      annotations: [],
      categories: [],
      paginate: ["annotations"],
      total: 50,
    };
  },
  computed: {
    series: function () {
      return {
        id: this.manifest_id,
        title: this.manifest.label,
      };
    },
    work: function () {
      // canvas apiから作品情報の取り出し
      let data = {
        id: 0,
        title: "",
        title_kana: "",
        image: "",
        image_width: 0,
        author: "",
        author_kana: "",
        publisher: "---",
        issued: "",
        issued_wareki: "",
        size: "",
        description: "",
        signature: "",
        interpretation: "",
        translation: "",
        published: "",
        modified: "",
      };
      if (this.canvas) {
        data.id = this.$route.params["id"];
        data.title = this.canvas.label;
        if (typeof this.canvas.images !== "undefined") {
          if (typeof this.canvas.images[0] !== "undefined") {
            if (typeof this.canvas.images[0].resource["@id"] !== "undefined") {
              data.image = this.canvas.images[0].resource["@id"];
              data.image_width = this.canvas.images[0].resource.width;
            }
          }
        }
        data.description = this.canvas.description;
        if (typeof this.canvas.metadata !== "undefined") {
          data.title_kana = this.findMetadata(
            this.canvas.metadata,
            "name_kana"
          );
          data.author = this.findMetadata(this.canvas.metadata, "author");
          data.author_kana = this.findMetadata(
            this.canvas.metadata,
            "author_kana"
          );
          data.issued = this.findMetadata(this.canvas.metadata, "issued");
          data.issued_wareki = this.findMetadata(
            this.canvas.metadata,
            "issued_wareki"
          );
          data.size = this.findMetadata(this.canvas.metadata, "size");
          data.signature = this.findMetadata(this.canvas.metadata, "signature");
          data.interpretation = this.findMetadata(
            this.canvas.metadata,
            "interpretation"
          );
          data.translation = this.findMetadata(
            this.canvas.metadata,
            "translation"
          );
          data.published = this.findMetadata(this.canvas.metadata, "published");
          data.modified = this.findMetadata(this.canvas.metadata, "modified");
        }
      }
      return data;
    },
    ebikis: function () {
      // 図像・事物一覧用
      let data = [];
      if (this.annotations) {
        this.annotations.forEach((_, i) => {
          let category = this.categories.find(
            (item) => item.id == this.annotations[i].layer_id
          );
          let ebiki = {
            id: this.annotations[i].id,
            image: this.annotations[i].image,
            name: this.annotations[i].name,
            name_kana: this.annotations[i].name_kana,
            category_id: this.annotations[i].layer_id.match(/\d+$/)[0],
            category_name: category ? category.name : "test",
            category_name_kana: category ? category.name_kana : "test",
          };
          if (typeof this.annotations[i].on !== "undefined") {
            let xywh = this.annotations[i].on.match(
              /#xywh=(\d+),(\d+),(\d+),(\d+)/
            );
            if (xywh[0]) {
              ebiki.x = xywh[1] * this.scaledown_rate;
              ebiki.y = xywh[2] * this.scaledown_rate;
              ebiki.w = xywh[3] * this.scaledown_rate;
              ebiki.h = xywh[4] * this.scaledown_rate;
            }
          }
          data.push(ebiki);
        });
      }
      if (data) {
        if (this.sort.key) {
          data.sort((a, b) => {
            a = a[this.sort.key];
            b = b[this.sort.key];

            return (
              (a === b ? 0 : a > b ? 1 : -1) *
              (this.sort.direction === "desc" ? -1 : 1)
            );
          });
        }
      }
      return data;
    },
    scaledown_rate: function () {
      let rate = 0;
      if (this.work.image_width) {
        rate = 300 / this.work.image_width;
      }
      return rate;
    },
  },
  watch: {
    $route: {
      handler: function () {
        this.sort.key = "category_name_kana";
        this.sort.direction = "asc";
      },
      immediate: true,
      deep: true,
    },
    annotationlist: {
      handler: async function () {
        if (this.annotationlist) {
          // annotationlist apiから絵引情報の取り出し
          this.annotationlist.resources.forEach((_, j) => {
            let data = {};
            data.id = this.annotationlist.resources[j]["@id"].match(/\d+$/)[0];
            data.name = this.annotationlist.resources[j].label;
            if (
              typeof this.annotationlist.resources[j].metadata !== "undefined"
            ) {
              data.name_kana = this.findMetadata(
                this.annotationlist.resources[j].metadata,
                "name_kana"
              );
            }
            if (
              typeof this.annotationlist.resources[j].thumbnail !== "undefined"
            ) {
              data.image = this.annotationlist.resources[j].thumbnail["@id"];
            }
            if (typeof this.annotationlist.resources[j].on !== "undefined") {
              data.on = this.annotationlist.resources[j].on;
            }
            data.layer_id = this.annotationlist.within["@id"];
            this.annotations.push(data);
          });
        }
      },
      deep: true,
    },
    "annotationlist.within": async function () {
      if (this.annotationlist.within) {
        // layer apiからカテゴリー情報の取得
        let layer;
        layer = await this.getLayer(this.annotationlist.within["@id"]);
        if (layer) {
          let data = {};
          data.id = layer["@id"];
          data.name = layer.label;
          if (typeof layer.metadata !== "undefined") {
            data.name_kana = this.findMetadata(layer.metadata, "name_kana");
          }
          this.categories.push(data);
        }
      }
    },
    ebikis: function () {
      if (this.ebikis) {
        this.total = this.ebikis.length;
      }
    },
  },
  mounted: async function () {
    this.canvas = await this.getCanvas(this.manifest_id, this.work_id);
    // canvas apiに紐づいているannotationlist apiの取得
    if (this.canvas.otherContent) {
      this.canvas.otherContent.forEach(async (_, i) => {
        this.annotationlist = await this.getAnnotationList(
          this.canvas.otherContent[i]["@id"]
        );
      });
    }
    // 昔からあったjQuery
    $(function () {
      var win = $(window);
      var area = $("#MOVABLE-AREA").wrap($('<div id="MOVABLE-AREA-BG"></div>'));
      var bg = $("#MOVABLE-AREA-BG");

      var jq = $.support;
      var prop = jq.msie && jq.version == "6.0" ? "height" : "min-height";
      bg.css(
        prop,
        $("#CONTENT-BODY div.pane-binder").height() - bg.position().top
      );

      win.scroll(function () {
        area.stop();
        var otop = bg.offset().top;
        var stop = win.scrollTop();
        if (otop < stop) {
          var sabun = stop - otop;
          area.animate({ paddingTop: sabun + 10 + "px" }, "normal");
        } else {
          area.animate({ paddingTop: 0 }, "normal");
        }
      });
    });
  },
  methods: {
    // カンバスAPIの読み込み
    getCanvas: function (manifest_id, canvas_id) {
      return iiifCanvas
        .get(manifest_id, canvas_id)
        .then((response) => {
          return response.data;
        })
        .catch((err) => console.log(err));
    },
    // アノテーションリストAPIの読み込み
    getAnnotationList: function (url) {
      return iiifAnnotationList
        .getByURL(url)
        .then((response) => {
          return response.data;
        })
        .catch((err) => console.log(err));
    },
    // レイヤーAPIの読み込み
    getLayer: function (url) {
      return iiifLayer
        .getByURL(url)
        .then((response) => {
          return response.data;
        })
        .catch((err) => console.log(err));
    },
    // 作品画像の絵引部分をハイライトする
    focusInEbiki: function (ebiki_id) {
      if (this.work.image_width) {
        // 引数 --> (x座標, y座標, 横幅, 縦幅)
        const ebiki = this.ebikis.find((item) => item.id == ebiki_id);
        $("#PAINTING").ebiki(ebiki.x, ebiki.y, ebiki.w, ebiki.h);
      }
    },
    // 作品画像の絵引部分のハイライトを解除する
    focusOutEbiki: function () {
      $("#PAINTING").cancelEbiki();
    },
  },
  head: {
    title: function () {
      return {
        inner: this.sitename + " " + this.title + " - " + this.orgname,
      };
    },
    meta: function () {
      return [
        { name: "description", content: this.description },
        { name: "keywords", content: this.keywords },
        {
          property: "og:title",
          content: this.sitename + " " + this.title + " - " + this.orgname,
        },
        { property: "og:description", content: this.description },
      ];
    },
  },
};
</script>
